<template>
  <div class="container start-test">
    <div class="title">
      <br />
      <div v-if="isFinished">
        <el-alert
          title="你已經完成分班考試，請耐心等待分班結果。"
          type="success"
          :closable="false"
          show-icon
        >
        </el-alert>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <h2 style="margin: 20px 0 0 0" v-if="enrollmentId > 0">Diagnostic Test</h2>
          <h2 style="margin: 20px 0 0 0" v-if="userExamId > 0">Download Test</h2>
        </div>
      </div>
      <hr />

      <div>
        <el-form label-width="120px" :model="form">
          <!-- <el-form-item label="Student:" >
            {{ form.student_first_name + " " + form.student_first_name }}
          </el-form-item> -->
          <el-form-item label="Course:" v-if="enrollmentId > 0">
            {{ form.course_session }}
          </el-form-item>
          <el-form-item label="Test:">
            <el-button type="text" @click="downloadFile(form.exam_id)">
              <i class="el-icon-document"></i>
              {{ form.assign_test }}
              <i class="el-icon-download el-icon--right"></i>
            </el-button>
          </el-form-item>
          <!-- <el-form-item label="Essay:" v-if="!isFinished">
            <el-radio-group v-model="hasEssay" size="small">
              <el-radio-button label="1">Yes</el-radio-button>
              <el-radio-button label="0">No</el-radio-button>
            </el-radio-group>
          </el-form-item> -->
        </el-form>
        <el-alert
          title="Please Download and PRINT OUT the test."
          type="warning"
          :closable="false"
        >
        </el-alert>
        <el-divider></el-divider>
        <div class="text-center" v-if="!isFinished">
          <el-button type="success" @click="startTimer">
            Go To Test Timer Page
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
// import TOEFL from "@/apis/tests.js";
import enrollmentApi from "@/apis/enrollment";
import Tests from "@/apis/tests.js";

export default {
  metaInfo() {
    return {
      title: "Diagnostic Test - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      form: {
        assign_test: "",
        exam_id: 0
      },
      hasEssay: "0"
    };
  },
  computed: {
    enrollmentId() {
      let enrollmentId = 0;
      if (this.$route.query.enrollment_id) {
        enrollmentId = parseInt(this.$route.query.enrollment_id);
      }
      return enrollmentId;
    },
    userExamId() {
      let userExamId = 0;
      if (this.$route.query.user_exam_id) {
        userExamId = parseInt(this.$route.query.user_exam_id);
      }
      return userExamId ;
    },
    isFinished() {
      return false;
    }
  },
  watch: {},

  mounted() {
    if(this.enrollmentId > 0){
      this.getEnrollmentTest();
    }
    if(this.userExamId > 0){
      this.getExamId();
    }
   
  },

  methods: {
    async getExamId() {
      const result = await Tests.getUserExamInfo(this.userExamId);
      this.form.assign_test= result.title;
      this.form.exam_id= result.exam_id;
      this.form.type= result.category;
      
    },
    async getEnrollmentTest() {
      let res = await enrollmentApi.getEnrollmentTest(this.enrollmentId);
      this.form = res;
    },
    startTimer() {
      if(this.userExamId > 0){
        this.routerPush({
          name: "Timer",
          query: {
            user_exam_id: this.userExamId,
            type: this.form.type
          }
        });
      }
      if(this.enrollmentId > 0){
        this.routerPush({
          name: "Timer",
          query: {
            enrollment_id: this.enrollmentId
          }
        });

      } 
     
    },
    downloadFile(exam_id) {
      let url = "/tests/start/download/" + exam_id;
      window.open(url, "_blank");
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
.start-test >>> .el-form-item__label {
  font-size: 14px;
  font-weight: 700;
}
.start-test >>> .el-form-item__content {
  font-size: 14px;
}
</style>
